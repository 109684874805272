import { Box, Center, Divider, Stack, Text, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  FurtherInfoCaptureActivity,
  CustomQuestionItem,
  CustomQuestionAttendeeAnswer,
} from 'interfaces';
import { useMutation } from '@apollo/client';
import { CaptureAdditionalInfoForBooking } from 'graphql/mutations';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useMemo, useState } from 'react';
import FurtherInformationSuccessModal from './FurtherInformationSuccessModal/FurtherInformationSuccessModal';
import { trackAction, Actions } from 'utils/amplitude';
import classes from './FurtherInformationForm.module.scss';
import CustomQuestionCard from './components/CustomQuestionCard/CustomQuestionCard';
import { CustomQuestionTypeEnum, PebbleButtonsEnum, StepperEnum } from 'enums';
import classNames from 'classnames';
import { PebbleButtonSet } from 'components/ui';
import ProgressBar from './ProgressBar/ProgressBar';
import { GetBasket } from 'graphql/queries';

interface IFurtherInformationFormProps {
  furtherInfoActivity?: FurtherInfoCaptureActivity;
  bookingId?: string;
  userToken?: string;
  precheckout?: boolean;
  onPrecheckoutSubmit?: (customQuestions: CustomQuestionItem[]) => void;
  customQuestions?: CustomQuestionItem[];
  setClosedModalFormValues?(value: CustomQuestionItem[]): void;
  supplierName?: string;
  steps?: StepperEnum[];
  activeStep?: StepperEnum;
}

export const DESKTOP_FIC_FORM_WIDTH = 660;

const FurtherInformationForm: React.FC<IFurtherInformationFormProps> = ({
  furtherInfoActivity,
  bookingId,
  userToken,
  customQuestions,
  precheckout = false,
  onPrecheckoutSubmit,
  supplierName,
  steps,
  activeStep,
}: IFurtherInformationFormProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [submitFurtherInformationMutation] = useMutation(CaptureAdditionalInfoForBooking, {
    onError: (error) => {
      console.log(error);
    },
    refetchQueries: [GetBasket],
  });

  const [successModalOpened, setSuccessModalOpened] = useState(false);

  // builds the attendeeAnswers array for each question where isPerAttendee is true
  const formatFurtherInfoQuestionAnswers = (_furtherInfoActivity?: FurtherInfoCaptureActivity) => {
    const formatted = _furtherInfoActivity?.questionAnswers.map((questionAnswer) => {
      if (questionAnswer.question.isPerAttendee) {
        // handle pre-populated attendee answers
        if (questionAnswer.attendeeAnswers && questionAnswer.attendeeAnswers.length > 0) {
          const attendeeAnswers = _furtherInfoActivity.attendees.map((attendee) => {
            const foundAttendee = questionAnswer.attendeeAnswers?.find(
              (answer) => answer.attendeeId === attendee.id,
            );

            return {
              attendeeId: attendee.id,
              fullName: attendee.fullName,
              answer: foundAttendee?.answer || '',
            };
          });

          return {
            ...questionAnswer,
            attendeeAnswers,
          };
        }

        const attendeeAnswers = _furtherInfoActivity.attendees.map((attendee) => {
          return {
            attendeeId: attendee.id,
            fullName: attendee.fullName,
            answer: '',
          };
        });

        return {
          ...questionAnswer,
          attendeeAnswers,
        };
      }

      return questionAnswer;
    });

    return formatted;
  };

  const initialValues: {
    customQuestions: CustomQuestionItem[];
  } = {
    customQuestions: customQuestions || formatFurtherInfoQuestionAnswers(furtherInfoActivity) || [],
  };

  const form = useForm({
    initialValues,
    validate: {
      customQuestions: {
        attendeeAnswers: (value) => {
          if (value === null) {
            return null;
          }
          return value.every((attendee) => Boolean(attendee.answer))
            ? null
            : 'Please answer for each attendee';
        },
        bookerAnswer: (value, values, path) => {
          const index = path.split('.')[1];
          const matchingQuestion = customQuestions?.[Number(index)];
          const isPerAttendee = matchingQuestion?.attendeeAnswers !== null;
          const isCheckbox = matchingQuestion?.question.type === CustomQuestionTypeEnum.CHECKBOX;
          if (isPerAttendee || isCheckbox) {
            return null;
          }
          return Boolean(value) ? null : 'Please answer this question';
        },
      },
    },
  });

  useEffect(() => {
    if (!customQuestions) return;
    form.setValues({ customQuestions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customQuestions]);

  const hasErrors = useMemo(() => {
    return Object.keys(form.errors).length > 0;
  }, [form.errors]);

  const handleUpdateAttendeeAnswer = (questionId: string, attendeeId: string, value: string) => {
    const questionIndex = form.values.customQuestions.findIndex(
      (question) => question.id === questionId,
    );

    // Create a copy of the customQuestions array
    const updatedCustomQuestions = [...form.values.customQuestions];
    // Update the specific question in the copied array
    const updatedQuestion = {
      ...updatedCustomQuestions[questionIndex],
      attendeeAnswers: (updatedCustomQuestions[questionIndex].attendeeAnswers || []).map(
        (attendee) => {
          if (attendee.attendeeId === attendeeId) {
            return {
              ...attendee,
              answer: value,
            };
          }
          return attendee;
        },
      ),
    };

    // Update the copied array with the updated question object
    updatedCustomQuestions[questionIndex] = updatedQuestion;

    form.setFieldValue(`customQuestions`, updatedCustomQuestions);
  };

  const handleUpdateBookerAnswer = (questionId: string, value: string | null) => {
    const questionIndex = form.values.customQuestions.findIndex(
      (question) => question.id === questionId,
    );

    const updatedCustomQuestions = [...form.values.customQuestions];

    const updatedQuestion = {
      ...updatedCustomQuestions[questionIndex],
      bookerAnswer: value,
    };
    updatedCustomQuestions[questionIndex] = updatedQuestion;

    form.setFieldValue('customQuestions', updatedCustomQuestions);
  };

  const getInputQuestionAnswers = () => {
    return form.values.customQuestions.map((question) => {
      // booker answer
      if (!question.attendeeAnswers && !question.question.isPerAttendee) {
        return {
          id: question.id,
          bookerAnswer: question.bookerAnswer,
        };
      }
      // attendee answers
      else {
        return {
          id: question.id,
          attendeeAnswers: question.attendeeAnswers?.map((attendeeAnswer) => ({
            attendeeId: attendeeAnswer.attendeeId,
            answer: attendeeAnswer.answer,
          })),
        };
      }
    });
  };

  const getInput = () => {
    return {
      activityBooking: bookingId,
      questionAnswers: getInputQuestionAnswers(),
    };
  };

  const handleSubmitForm = async () => {
    await submitFurtherInformationMutation({
      variables: {
        input: getInput(),
      },
      ...(userToken && {
        context: {
          headers: {
            Authorization: `${userToken}`,
          },
        },
      }),
    });

    trackAction(Actions.POST_CHECKOUT_SUBMIT_INFORMATION);

    setSuccessModalOpened(true);
  };

  const getError = (customQuestion: CustomQuestionItem, index: number) => {
    const bookerError = customQuestion.attendeeAnswers === null;
    return bookerError
      ? form.errors?.[`customQuestions.${index}.bookerAnswer`]
      : form.errors?.[`customQuestions.${index}.attendeeAnswers`];
  };

  const [answeredQuestions, setAnsweredQuestions] = useState(0);

  useEffect(() => {
    const answeredCount = form.values.customQuestions.reduce((acc, question) => {
      if (question.attendeeAnswers) {
        const allAttendeesAnswered = question.attendeeAnswers.every(
          (attendee) => attendee.answer?.trim() !== '',
        );

        if (allAttendeesAnswered) {
          return acc + 1;
        }
      } else if (question.bookerAnswer) {
        return acc + 1;
      }
      return acc;
    }, 0);

    setAnsweredQuestions(answeredCount);
  }, [form.values.customQuestions]);

  const filteredQuestions = form.values.customQuestions.map((customQuestion) => {
    if (
      customQuestion.attendeeAnswers &&
      customQuestion.attendeeAnswers.length > 0 &&
      customQuestion.question.isPerAttendee
    ) {
      const uniqueAttendeesAnswers: CustomQuestionAttendeeAnswer[] = [];
      const attendeeIds = new Set();

      // Loop through each attendeeAnswer + only add if the attendeeId has not been added yet
      customQuestion.attendeeAnswers.forEach((attendeeAnswer) => {
        if (!attendeeIds.has(attendeeAnswer.attendeeId)) {
          uniqueAttendeesAnswers.push(attendeeAnswer);
          attendeeIds.add(attendeeAnswer.attendeeId);
        }
      });
      return {
        ...customQuestion,
        attendeeAnswers: uniqueAttendeesAnswers,
      };
    }

    return customQuestion;
  });

  return (
    <>
      {!precheckout && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: `${isMobile ? theme.spacing.xl : theme.spacing.xl} ${
              isMobile ? theme.spacing.lg : 0
            }`,
          }}
        >
          <ProgressBar
            totalQuestions={form.values.customQuestions.length}
            answeredQuestions={answeredQuestions}
          />
        </div>
      )}

      <Center>
        <Box
          w={isMobile ? '100%' : DESKTOP_FIC_FORM_WIDTH}
          className={classNames({
            [classes.formWrapper]: !precheckout,
            [classes.precheckoutFormWrapper]: precheckout,
          })}
        >
          {!precheckout && (
            <>
              <Stack gap={8}>
                <Text className={classes.header}>{furtherInfoActivity?.activityName}</Text>
                <Text
                  style={{
                    fontSize: '14px',
                    fontWeight: 600,
                  }}
                >
                  {furtherInfoActivity?.supplierName}
                </Text>
                <Text
                  c={theme.colors.gray[6]}
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {furtherInfoActivity?.activityAddress === 'None'
                    ? 'Online'
                    : furtherInfoActivity?.activityAddress}
                </Text>
              </Stack>
              <Divider mt={theme.spacing.sm} mb={theme.spacing.xl} />
            </>
          )}
          <Stack>
            <Stack gap={8}>
              <>
                <Text
                  c={theme.colors.blue[8]}
                  fw={700}
                  style={{
                    fontSize: '20px',
                  }}
                >
                  Add Additional Information about Attendees
                </Text>
                <Text className={classes.textInfo}>
                  Please take a moment to add some additional medical and relevant information about
                  the attendees.
                </Text>
              </>
            </Stack>

            {precheckout && (
              <ProgressBar
                totalQuestions={form.values.customQuestions.length}
                answeredQuestions={answeredQuestions}
                precheckout={true}
              />
            )}

            <Stack gap={0}>
              {filteredQuestions.map((customQuestion, index) => (
                <CustomQuestionCard
                  key={customQuestion.id}
                  customQuestion={customQuestion}
                  handleUpdateAttendeeAnswer={handleUpdateAttendeeAnswer}
                  handleUpdateBookerAnswer={handleUpdateBookerAnswer}
                  supplierName={supplierName || furtherInfoActivity?.supplierName || ''}
                  precheckout={precheckout}
                  error={getError(customQuestion, index)}
                />
              ))}
            </Stack>

            <PebbleButtonSet
              btnVariant={PebbleButtonsEnum.PRIMARY}
              size={precheckout ? 'md' : 'lg'}
              fullWidth
              onClick={() => {
                form.validate();

                if (precheckout && form.isValid()) {
                  onPrecheckoutSubmit?.(form.values.customQuestions);

                  if (steps && activeStep) {
                    const nextStep = steps[steps.indexOf(activeStep) + 1];

                    if (nextStep === StepperEnum.SELECT_ADDONS) {
                      return trackAction(Actions.CHECKOUT_NEXT_ADDONS);
                    } else {
                      return trackAction(Actions.CHECKOUT_NEXT_REVIEWPAY);
                    }
                  }
                  return;
                } else if (form.isValid()) {
                  handleSubmitForm();
                }
              }}
            >
              {precheckout ? 'Next' : 'Confirm Answers'}
            </PebbleButtonSet>
            {hasErrors && (
              <Text c={theme.colors.red[8]} size="sm">
                Please answer all questions
              </Text>
            )}
          </Stack>

          <FurtherInformationSuccessModal
            opened={successModalOpened}
            onClose={() => setSuccessModalOpened(false)}
          />
        </Box>
      </Center>
    </>
  );
};

export default FurtherInformationForm;
